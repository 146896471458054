<template>
    <div
        class="min-h-login p-4 lg:p-9 flex flex-col h-full w-full items-center justify-center gap-9 justify-center"
    >
        <Logo />
        <slot />
    </div>
</template>

<script lang="ts">
import Logo from '@/components/UI/images/Logo.vue'

export default {
    components: { Logo },
    mounted() {
        this.$i18n.setLocale((this.$route.query.lang as string) || 'fr')
    }
}
</script>
